import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvironmentService } from 'src/app/core/services/environment.service';
import { API_URL } from 'src/environments/settings';
import { NotificationGroup } from '../interfaces/notification-group.interface';

@Injectable({providedIn: 'root'})
export class NotificationGroupService {
  
  private apiUrl: string;
  
  constructor(
    private http: HttpClient,
    private env: EnvironmentService
  ) {
    this.apiUrl = this.env.getValueTransferState(API_URL);
  }

  getNotificationGroups(skipCount: number, maxResultsCount: number) {
    const params: HttpParams = new HttpParams({
      fromObject: {
        MaxResultCount: maxResultsCount,
        SkipCount: skipCount 
      }
    });
    return this.http.get<any>(`${this.apiUrl}/app/notificationGroup`, { params });
  }

  getNotificationGroupById(id: string): Observable<NotificationGroup> {
    return this.http.get<NotificationGroup>(`${this.apiUrl}/app/notificationGroup/${id}`);
  }

  updateNotificationGroup(notificationGroup: NotificationGroup): Observable<NotificationGroup> {
    return this.http.put<NotificationGroup>(`${this.apiUrl}/app/notificationGroup/${notificationGroup.id}`, notificationGroup);
  }

  createNotificationGroup(notificationGroup: NotificationGroup): Observable<NotificationGroup> {
    return this.http.post<NotificationGroup>(`${this.apiUrl}/app/notificationGroup`, notificationGroup);
  }

  removeNotificationGroup(id: string) {    
    return this.http.delete(`${this.apiUrl}/app/notificationGroup/${id}`);
  }

  getNotificationGroupTypes() {
    return this.http.post<any>(`${this.apiUrl}/app/prefillProvider/getPrefillData`, {
      includeRequests: [
        {
          entityName: "NotificationGroupType",
          keyAttributeName: "Id",
          valueAttributeName: "Name",
          includeAs: "notificationGroupTypes"
        }
      ]
    }).pipe(
      map(res => res.prefillData),
      map((data: any) => {
        return data[0].filterData.map(item => {
          return { text: item.value, value: item.key };
        });
      })
    );
  }
}
