import { SelectOption } from "src/app/shared/interfaces/select-option.interface";
import { NotificationGroup } from "../interfaces/notification-group.interface";

export class NotificationGroupStateModel {
  loading: boolean;
  saving: boolean;
  
  items?: NotificationGroup[];
  totalCount?: number;

  notificationGroupTypes: any[];
  filteredUsers: SelectOption[];
}